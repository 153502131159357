<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name:
            this.backLinkName.indexOf('r_edit') !== -1
              ? 'r_visitor-edit-containers-help-online'
              : 'r_visitor-add-containers-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "add-containers-to-visitor",
              "containers"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName(
                'visitor-management',
                'add-containers-to-visitor',
                'search'
              )
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section pill" v-if="containers && containers.length">
      <li
        class="clebex-item-section-item"
        v-for="container in containers"
        :key="container.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedContainer(container)"
        >
          <span class="label">
            <span class="highlight">{{ container.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedContainers &&
                  selectedContainers.length &&
                  selectedContainers.filter(item => item.id === container.id)
                    .length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "AddAssignableContainersToVisitor",
  mixins: [helpOnlineMixin],
  data() {
    return {
      searchQuery: "",
      helpSlug: "visitor-containers"
    };
  },
  computed: {
    ...mapState("user", ["listOfAssignableContainers"]),
    ...mapState("visitorManagement", [
      "selectedContainers",
      "allowedContainers",
      "receptionDeskInfo"
    ]),
    containers() {
      const query = this.searchQuery;
      if (this.allowedContainers && this.allowedContainers.length) {
        if (query && query.length > 1) {
          return this.allowedContainers.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.allowedContainers;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("user", ["getUserAssignableContainers"]),
    ...mapActions("visitorManagement", [
      "setSelectedContainer",
      "getAllowedContainers"
    ])
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
